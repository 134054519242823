import React, { Component } from 'react'

import './Home.css'
import {history} from '../_helpers'

class Intro extends Component {
  goToHome(e) {
    history.push('/home')
    window.location.href = '/home'
  }
  render() {
    return (
      <section id="section0" className="embed-responsive" data-aos="fade-in">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/P756FaGeAro?controls=0&rel=0&autoplay=1&loop=1&showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video intro" />
        <div className="more w-100" onClick={this.goToHome}>
          <div className="text">
            Vive la experiencia
          </div>
        </div>
      </section>
    )
  }
}

export default Intro