import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import {history} from './_helpers'



import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './fonts/bebas/bebas.css'
import './fonts/dina/stylesheet.css'
import './fonts/flaticon/flaticon.css'
import './App.css';

import Home from './Pages/Home'
import Intro from './Pages/Intro'

class App extends Component {
  componentDidMount() {
    AOS.init({
      duration : 1000
    })
  }
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <Switch>
            <Route path="/" exact component={Intro} />
            <Route path="/home" exact component={Home}/>
          </Switch>
        </div>
      </Router>

    );
  }
}

export default App;
