import React, { Component } from 'react'
import logo from '../img/logo-green.svg'
import menu from '../img/menu.png'

class Header extends Component {

    render() {
        return(
            <header className="w-100 head" data-aos="fade-in">
                <img src={logo} alt="logo" className="float-left logo" onClick={this.props.action}/>
                <img src={menu} alt="menu" className="float-right logo" onClick={this.props.actionMenu}/>
            </header>
        )
    }
}

export default Header