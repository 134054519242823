import React, {Component} from 'react'

class Avatar extends Component {

  render() {
    return (
      <div className="col-6 col-md-3">
        <div className="rounded-circle">
          <img src={this.props.avatar.img} alt="crew" className="img-fluid rounded-circle"/>
          <div className="overlay align-items-end text-center justify-content-center">
            <div className="copy text-center text-white">
              <div className="name">
                {this.props.avatar.name}
              </div>
              <div className="puesto">
                {this.props.avatar.puesto}
              </div>
            </div>
          </div>
        </div>
        <br/>
      </div>
    )
  }

}

export default Avatar