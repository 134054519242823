import React, {Component} from 'react'

class Menu extends Component {
  render() {
    return (
      <div className="menu" data-aos="fade-in">
        <ul className="navbar-m">
          <li onClick={this.props.link1}>&iquest;Quiénes somos?</li>
          <li onClick={this.props.link2}>&iquest;Qué hacemos?</li>
          <li onClick={this.props.link3}>Clientes</li>
          <li onClick={this.props.link4}>Equipo</li>
          <li onClick={this.props.link5}>Contacto</li>
        </ul>
        <ul className="w-100 d-flex flex-row bottom justify-content-center">
          <li><a href="https://es-la.facebook.com/greenmktp/" target="_blank" rel="noopener noreferrer"><i className="flaticon-facebook"></i></a></li>
          <li><a href="https://twitter.com/GreenMKTP" target="_blank" rel="noopener noreferrer"><i className="flaticon-social"></i></a></li>
          <li><a href="https://www.youtube.com/channel/UCYnzcST9BhYi00Wjs2awuWQ" target="_blank" rel="noopener noreferrer"><i className="flaticon-youtube"></i></a></li>
          <li><a href="https://www.linkedin.com/company/greenmkt/" target="_blank" rel="noopener noreferrer"><i className="flaticon-linkedin"></i></a></li>
          <li><a href="https://www.instagram.com/greenmktp/" target="_blank" rel="noopener noreferrer"><i className="flaticon-instagram"></i></a></li>
        </ul>
      </div>
    )
  }
}

export default Menu