import React, {Component} from 'react'

class Customer extends Component {
  render() {
    return (
      <div className="col-6 col-sm-6 col-md-2 text-center customer align-items-center d-flex" data-aos="flip-left">
        <img src={this.props.customer.img} alt={this.props.customer.name} title={this.props.customer.name} className="img-fluid"/>
      </div>
    )
  }
}

export default Customer