import React, { Component } from 'react'
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../Components/Header'
import Menu from '../Components/Menu'
import Avatar from '../Components/Avatar'
import Customer from '../Components/Customer'

import './Home.css'

import gLetter from '../img/who/1-g.png'
import rLetter from '../img/who/2-r.png'
import eLetter from '../img/who/3-e.png'
import nLetter from '../img/who/5-n.png'

import exploracion from '../img/services/explora-ico.png'
import estrategia from '../img/services/estrategia-ico.png'
import creacion from '../img/services/creacion.png'
import accion from '../img/services/accion.png'
import servicios from '../img/services/textos.png'

import crew1_1 from '../img/crew/crew1-1.jpg'
import crew1_2 from '../img/crew/crew1-2.jpg'
import crew1_3 from '../img/crew/crew1-3.jpg'
import crew1_4 from '../img/crew/crew1-4.jpg'
import crew1_5 from '../img/crew/crew1-5.jpg'
import crew1_6 from '../img/crew/crew1-6.jpg'
import crew1_7 from '../img/crew/crew1-7.jpg'
import crew1_8 from '../img/crew/crew1-8.jpg'
import crew_desk from '../img/crew/grupal-desktop-completa-bajaQ.jpg'
import crew_mob1 from '../img/crew/grupal-mobile-parte1.jpg'
import crew_mob2 from '../img/crew/grupal-mobile-parte2.jpg'

import client1 from '../img/customers/1-logo.svg'
import client2 from '../img/customers/2-logo.svg'
import client3 from '../img/customers/3-logo.svg'
import client4 from '../img/customers/4-logo.svg'
import client5 from '../img/customers/5-logo.svg'
import client6 from '../img/customers/6-logo.svg'
import client7 from '../img/customers/7-logo.svg'
import client8 from '../img/customers/8-logo.svg'
import client9 from '../img/customers/9-logo.svg'
import client10 from '../img/customers/10-logo.svg'
import client11 from '../img/customers/11-logo.svg'
import client12 from '../img/customers/12-logo.svg'
import client13 from '../img/customers/13-logo.svg'
import client14 from '../img/customers/14-logo.svg'
import client15 from '../img/customers/15-logo.svg'
import client16 from '../img/customers/16-logo.svg'
import client17 from '../img/customers/17-logo.svg'
import client18 from '../img/customers/18-logo.svg'


class Home extends Component {

  constructor () {
    super()

    this.state = {
      form: {
        name: '',
        email: '',
        sede: '',
        subject: '',
        msg: ''
      },
      valids: {
        name: false,
        email: false,
        sede: false,
        subject: false,
        msg: false
      },
      showHeader: true,
      showMenu: false,
      sendData: false
    }

    this.goToSection1 = this.goToSection1.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.section2 = this.section2.bind(this)
    this.section3 = this.section3.bind(this)
    this.section4 = this.section4.bind(this)
    this.section5 = this.section5.bind(this)
    this.section6 = this.section6.bind(this)

    this.changeValue = this.changeValue.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  goToSection1 (e) {
    document.querySelector('#section1').scrollIntoView({block: 'start', behavior: 'smooth', duration: 1000})
  }

  submitForm (e) {
    e.preventDefault()
    let {valids, form} = this.state
    //console.log(valids, form)
    this.setState({ sendData: true })
    if(valids.name && valids.email && valids.sede && valids.subject && valids.msg){
      axios.post('/senmail.php', form).then((response)=>{
        let responses = response.data
        console.log(responses.status)
        if(responses.status === 1) {
          this.setState({ form: {...this.state.form, name: '' } })
          this.setState({ form: {...this.state.form, email: '' } })
          this.setState({ form: {...this.state.form, sede: '' } })
          this.setState({ form: {...this.state.form, msg: ''} })
          this.setState({ form: {...this.state.form, subject: '' } })
          this.setState({ sendData: false })
          toast.success(responses.message)
        } else {
          this.setState({ sendData: false })
          toast.error(responses.message)
        }
      })
    }
  }

  openMenu (e) {
    if (this.state.showMenu) {
      this.setState({showMenu: false})
    } else {
      this.setState({showMenu: true})
    }

  }

  section2 (e) {
    this.setState({showMenu: false})
    document.querySelector('#section2').scrollIntoView({block: 'start', behavior: 'smooth', duration: 1000})
  }

  section3 (e) {
    this.setState({showMenu: false})
    document.querySelector('#section3').scrollIntoView({block: 'start', behavior: 'smooth', duration: 1000})
  }

  section4 (e) {
    this.setState({showMenu: false})
    document.querySelector('#section4').scrollIntoView({block: 'start', behavior: 'smooth', duration: 1000})
  }

  section5 (e) {
    this.setState({showMenu: false})
    document.querySelector('#section5').scrollIntoView({block: 'start', behavior: 'smooth', duration: 1000})
  }

  section6 (e) {
    this.setState({showMenu: false})
    document.querySelector('#section6').scrollIntoView({block: 'start', behavior: 'smooth', duration: 1000})
  }

  changeValue (e) {
    let name = e.target.name
    let value = e.target.value
    if(value.length>=1) {
      this.setState({ valids: {...this.state.valids, [name]: true} })
    } else {
      this.setState({ valids: {...this.state.valids, [name]: false} })
    }
    this.setState({ form: {...this.state.form, [name]: value}})
  }

  render () {
    let crews = [
      {id: 1, name: 'José Luis Alva', puesto: 'CEO', img: crew1_1},
      {id: 2, name: 'Román Barrera', puesto: 'DGC', img: crew1_2},
      {id: 3, name: 'Rodrigo Villagómez', puesto: 'CFO', img: crew1_3},
      {id: 4, name: 'Diego Alva', puesto: 'Productor General', img: crew1_4},
      {id: 5, name: 'Rodrigo Ballesteros', puesto: 'Director de Cuentas', img: crew1_5},
      {id: 6, name: 'Jessica Pérez', puesto: 'Gerente de Trade', img: crew1_6},
      {id: 7, name: 'Diego Galicia', puesto: 'Gerente de Estrategias', img: crew1_7},
      {id: 8, name: 'Rogelio Becerril', puesto: 'Director de Infraestructura', img: crew1_8},
    ]

    let customers = [
      {name: 'Fiesta americana', img: client3},
      {name: 'Bimbo', img: client6},
      {name: 'Banco Multiva', img: client7},
      {name: 'Posadas', img: client8},
      {name: 'Huawei', img: client9},
      {name: 'Litle Caesars', img: client11},
      {name: 'Unilever', img: client12},
      {name: 'Axe', img: client13},
      {name: 'Holanda', img: client14},
      {name: 'oxxo', img: client15},
      {name: 'Duracell', img: client16},
      {name: 'Ultra Mexico', img: client17},
      {name: 'Resistance', img: client18},
    ]

    let { form, sendData } = this.state

    return (
      <div className="homepage">
        {this.state.showHeader ? <Header action={this.goToSection1} actionMenu={this.openMenu}/> : null}
        {this.state.showMenu ? <Menu link1={this.section2} link2={this.section3} link3={this.section4}
                                     link4={this.section5} link5={this.section6}/> : null}

        <section id="section1">

        </section>
        <section id="section2">
          <div className="bg-yellow content">
            <div className="container borde">
              <div className="row">
                <div className="col-md-6" data-aos="fade-in">
                  <h2 className="text-uppercase text-white bg-yellow">&iquest;Qui&eacute;nes somos?</h2>
                </div>
                <div className="col-md-6 text-white" data-aos="fade-in">
                  <p>
                    <b>Desde 2006</b> somos la agencia de <b>BTL y experiencias </b>
                    donde las ideas nacen y crecen. Nuestra <b>creatividad y estrategia</b> est&aacute; enfocada en el
                    consumidor.
                    Es <b>r&aacute;pida y efectiva.</b> Siempre estamos listos para <b>ir m&aacute;s all&aacute;.</b>
                  </p>
                </div>
              </div>
              <div className="row flex-md-nowrap content-green justify-content-center">
                <div className="col-6 col-md text-center" data-aos="flip-right" data-aos-delay="50">
                  <img src={gLetter} alt="" className="img-fluid"/>
                  <br/>
                  <br/>
                  <h3 className="text-white text-uppercase">Guerreros</h3>
                </div>
                <div className="col-6 col-md text-center" data-aos="flip-right" data-aos-delay="100">
                  <img src={rLetter} alt="" className="img-fluid"/>
                  <br/>
                  <br/>
                  <h3 className="text-white text-uppercase">Responsables</h3>
                </div>
                <div className="col-6 col-md text-center" data-aos="flip-right" data-aos-delay="150">
                  <img src={eLetter} alt="" className="img-fluid"/>
                  <br/>
                  <br/>
                  <h3 className="text-white text-uppercase">Entregados</h3>
                </div>
                <div className="col-6 col-md text-center" data-aos="flip-right" data-aos-delay="200">
                  <img src={eLetter} alt="" className="img-fluid"/>
                  <br/>
                  <br/>
                  <h3 className="text-white text-uppercase">Exigentes</h3>
                </div>
                <div className="col-6 col-md text-center" data-aos="flip-right" data-aos-delay="250">
                  <img src={nLetter} alt="" className="img-fluid"/>
                  <br/>
                  <br/>
                  <h3 className="text-white text-uppercase">Necios</h3>
                </div>
              </div>
              <div className="row content-green text-center align-content-center">
                <h3 className="text-white text-uppercase w-100">
                  Movamos la aguja de tu marca. Diviértete con nosotros.
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* services */}
        <section id="section3">
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-3 align-content-center text-center" data-aos="flip-right"
                   data-aos-delay="100">
                <img src={exploracion} alt="exploracion" className="icons"/>
                <br/><br/>
                <h3 className="text-uppercase text-white title">Exploraci&oacute;n</h3>
                <p className="text-white description">
                  Nos adentramos en tu marca, su filosof&iacute;a y el contexto.
                </p>
              </div>
              <div className="col-6 col-md-3 align-content-center text-center" data-aos="flip-right"
                   data-aos-delay="200">
                <img src={estrategia} alt="estrategia" className="icons"/>
                <br/><br/>
                <h3 className="text-uppercase text-white title">Estrategia</h3>
                <p className="text-white description">
                  Estudiamos tus objetivos e identificamos las claves de tu &eacute;xito generando un plan contundente.
                </p>
              </div>
              <div className="col-6 col-md-3 align-content-center text-center" data-aos="flip-right"
                   data-aos-delay="300">
                <img src={creacion} alt="creacion" className="icons"/>
                <br/><br/>
                <h3 className="text-uppercase text-white title">Creaci&oacute;n</h3>
                <p className="text-white description">
                  Desarrollamos la mejor f&oacute;rmula creativa y experiencia para que tu cliente se enamore de tu
                  marca.
                </p>
              </div>
              <div className="col-6 col-md-3 align-content-center text-center" data-aos="flip-right"
                   data-aos-delay="400">
                <img src={accion} alt="accion" className="icons"/>
                <br/><br/>
                <h3 className="text-uppercase text-white title">Acci&oacute;n</h3>
                <p className="text-white description">
                  Amplificamos tu campa&ntilde;a y superamos KPIS como parte de tu equipo destacando la identidad de tu
                  marca.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-yellow content-services align-items-center align-content-center text-center"
               data-aos="flip-right" data-aos-delay="100">
            <img src={servicios} alt="servicios" className="img-fluid"/>
          </div>
        </section>
        {/* clients */}
        <section id="section4">
          <div className="bg-yellow content-clients">
            <div className="container borde" data-aos="fade-in">
              <div className="row">
                <div className="col-md-12" data-aos="fade-in">
                  <h2 className="bg-yellow text-uppercase text-white">Nuestros clientes</h2>
                </div>
              </div>
              <br/>
              <div className="row justify-content-center">
                {customers.map((custom, index) => {
                  return <Customer key={index} customer={custom}/>
                })}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <p className="text-white copy-mkt">"El marketing es el arte de convertir a un cliente en el héroe de tu
                  historia" <br/> - Ann Handley -</p>
              </div>
            </div>
          </div>
        </section>
        {/* crew */}
        <section id="section5">
          <div className="bg-yellow content">
            <div className="container borde" data-aos="fade-in" data-aos-delay="50">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="bg-yellow text-uppercase text-white">Nuestro crew</h2>
                </div>
              </div>
              <div className="row" id="carrusel">
                <div id="carousel" className="carousel slide d-none d-md-block d-lg-block d-xl-block" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="team-circle carousel-item active">
                      <div className="row">
                        {crews.map((crew, index) => {
                          return <Avatar key={index} avatar={crew}/>
                        })}
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src={crew_desk} alt="team" />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
                <div id="carouselm" className="carousel slide d-none d-xs-block d-sm-block d-md-none d-lg-none d-xl-none" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="team-circle carousel-item active">
                      <div className="row">
                        {crews.map((crew, index) => {
                          return <Avatar key={index} avatar={crew}/>
                        })}
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src={crew_mob1} alt="team 1" />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src={crew_mob2} alt="team 2" />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselm" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselm" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact */}
        <section id="section6">
          <div className="bg-yellow content-contact">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-white" data-aos="fade-in">
                  <h2 className="text-uppercase">Déjanos tu mensaje</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4" data-aos="fade">
                  <form onSubmit={this.submitForm}>
                    <div className="form-group">
                      <label htmlFor="name">Nombre (requerido)</label>
                      <input type="text" name="name" id="name" className="form-control" required onChange={this.changeValue} value={form.name}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Correo electr&oacute;nico (requerido)</label>
                      <input type="email" name="email" id="email" className="form-control" required onChange={this.changeValue} value={form.email}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject">Asunto</label>
                      <input type="text" name="subject" id="subject" className="form-control" required onChange={this.changeValue} value={form.subject}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="sede">Sede a contactar</label>
                      <select name="sede" id="sede" className="form-control" required onChange={this.changeValue} value={form.sede}>
                        <option value="">Selecciona una opción</option>
                        <option value="Corporativo">Corporativo</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Metropolitana">Metropolitana</option>
                        <option value="Occidente">Occidente Pacífico</option>
                        <option value="San Andrés">San Andrés</option>
                        <option value="Queremos escucharte">Queremos escucharte</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="msg">Mensaje</label>
                      <textarea name="msg" id="msg" className="form-control" required onChange={this.changeValue} value={form.msg}></textarea>
                    </div>

                    <div className="form-group text-center">
                      <button type="submit" disabled={sendData} className="btn btn-light btn-contact">Enviar</button>
                    </div>
                  </form>
                  <ToastContainer/>
                </div>
                <div className="col-md-8">
                  <br/>
                  <div className="row">
                    <div className="col-md-6" data-aos="fade-in">
                      <div className="card text-center text-white">
                        <h3 className="contact-h">Corporativo</h3>
                      </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-in">
                      <div className="card text-center text-white">
                        <h3 className="contact-h">Guerrero</h3>
                      </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-in">
                      <div className="card text-center text-white">
                        <h3 className="contact-h">Metropolitana La Loma</h3>
                      </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-in">
                      <div className="card text-center text-white">
                        <h3 className="contact-h">Occidente Pacífico</h3>
                      </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-in">
                      <div className="card text-center text-white">
                        <h3 className="contact-h">San Andrés</h3>
                      </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-in">
                      <div className="card text-center text-white">
                        <h3 className="contact-h">Puebla</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="email text-white text-center"><b>Correo Electrónico: <a
                        href="mailto:contacto@publicidadgreen.com.mx"
                        className="text-white">contacto@publicidadgreen.com.mx</a></b></div>
                      <div className="tel text-white text-center"><b>Teléfono: <a href="tel:+525524870101"
                                                                                  className="text-white">01 55 2487
                        0101</a></b></div>
                      <div className="tel text-white text-center"><b>Whatsapp: +525571107044</b></div>
                    </div>
                  </div>

                </div>
                <div className="col-md-12">
                  <div className="row align-items-center">
                    <div className="col-md-4 links">
                      <a href={`http://www.greenmkt.mx/Aviso-Privacidad-y-Derechos-Arco.pdf`} rel="noopener noreferrer" target="_blank">Aviso de Privacidad</a> &nbsp;&nbsp;

                      <a href={`http://www.greenmkt.mx/QueremosEscucharte.pdf`} rel="noopener noreferrer" target="_blank">C&oacute;digo de Conducta</a>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <ul className="d-flex flex-row">
                          <li><a href="https://es-la.facebook.com/greenmktp/" target="_blank" rel="noopener noreferrer"><i
                            className="flaticon-facebook"></i></a></li>
                          <li><a href="https://twitter.com/GreenMKTP" target="_blank" rel="noopener noreferrer"><i
                            className="flaticon-social"></i></a></li>
                          <li><a href="https://www.youtube.com/channel/UCYnzcST9BhYi00Wjs2awuWQ" target="_blank"
                                 rel="noopener noreferrer"><i className="flaticon-youtube"></i></a></li>
                          <li><a href="https://www.linkedin.com/company/greenmkt/" target="_blank"
                                 rel="noopener noreferrer"><i className="flaticon-linkedin"></i></a></li>
                          <li><a href="https://www.instagram.com/greenmktp/" target="_blank" rel="noopener noreferrer"><i
                            className="flaticon-instagram"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div className="row">
                <div className="text-center w-100">
                  <p className="text-white">GREEN &copy; {(new Date().getFullYear())} Todos los derechos reservados.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Home